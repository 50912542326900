<template>
    <div class="question-item">
        <div v-if="fileUrl" class="file-box">
            <div class="file-icon">
                <img v-if="fileIcon" :src="fileIcon" />
            </div>
            <h2>{{ fileName }}</h2>
            <div class="download"><Button type="primary">下载</Button></div>
        </div>
        <div v-else class="file-box">
            <div class="file-icon"></div>
            <h2>示例文件.pdf</h2>
        </div>
    </div>
</template>
<script>
export default {
    name: "DisplayFile",
    props: ["fileUrl", "fileName"],
    mounted() {
        this.width = this.$el.offsetWidth;
    },
    data() {
        return {
            width: 0,
        };
    },
    computed: {
        fileIcon() {
            let dotIndex = this.fileUrl.lastIndexOf(".");
            if (dotIndex >= 0) {
                let type = this.fileUrl
                    .substr(dotIndex + 1, this.fileUrl.length, dotIndex)
                    .toLowerCase();
                return "/file-icons/file-" + type + ".png";
            }
            return "";
        },
    },
};
</script>
<style lang="less" scoped>
.file-box {
    width: 305px;
    border: 1px solid #E8EAEC;
    border-radius: 5px;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: #fff;

    .file-icon {
        width: 281px;
        height: 165px;
        background-color: #f5f7fa;
        margin: 12px auto 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 96px;
        }
    }

    h2 {
        height: 24px;
        line-height: 24px;
        margin: 12px auto 12px auto;
        font-size: 16px;
        font-weight: normal;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .download {
        text-align: center;
        margin: 12px 0 16px 0;
    }
}
</style>